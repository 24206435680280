import { useTranslation } from 'react-i18next';
import logo_eu from '../../images/logo-eu.png'
import license from '../../images/licence-creative.svg'
import { Row } from 'antd';
const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer>
      <div>
        <img src={logo_eu} alt='Idea' width={200} />
             <a target="_blank" href="https://creativecommons.org/licenses/by-sa/4.0/?ref=chooser-v1">
                  <img src={license} alt='Idea' width={150} />
            </a>
      </div>
      <p>{t('Funded by the European Union. Views and opinions expressed are however those of the author(s) only and do not necessarily reflect those of the European Union or the European Education and Culture Executive Agency (EACEA). Neither the European Union nor EACEA can be held responsible for them.')}</p>
    </footer>
  )
}

export default Footer